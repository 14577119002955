<script>
  import {
    Heading, Span, DeviceMockup,
    GradientButton, Secondary,
    List, Li, Layout, Badge,
    P, Timeline, TimelineItem,
  } from "flowbite-svelte";
</script>

<main class="p-8 max-w-screen-md mx-auto w-full">
  <Heading tag="h1" customSize="text-5xl font-bold mb-10 text-center">
    Harness the power of <br />
    <Span
      gradient
      gradientClass="text-6xl bg-gradient-to-r from-red-400 to-violet-400 bg-clip-text text-transparent font-black"
      >generative AI</Span> <br />
    <Secondary class="text-4xl font-bold text-black">for skill assessments.</Secondary>
  </Heading>

  <Heading customSize="my-8">
    <Secondary class="text-3xl text-gray-800 my-10 font-medium">
      <img src="/logo-64.jpg" alt="SFIA GPT logo" class="w-8 h-8 inline relative bottom-1" />
      SFIA GPT is a fine-tuned GPT model designed to conduct skill assessments—starting with the SFIA framework.
      <a href="#request-access">
      <GradientButton shadow color="blue" class="my-8 mx-auto w-full">
        Try it!
      </GradientButton>
      </a>
    </Secondary>
  </Heading>

  <Layout class="flex flex-col md:flex-row" gap={8}>
    <DeviceMockup class="">
      <img src="/sfiagpt-phone-mockup.jpg" alt="SFIA GPT screenshot" />
    </DeviceMockup>

    <Heading tag="h2" customSize="text-3xl font-medium">
      <img src="/logo-64.jpg" alt="SFIA GPT logo" class="w-8 h-8 inline relative bottom-1" />
      SFIA GPT can conduct skill interviews
      <Secondary class="text-3xl text-gray-800">
        <List tag="ol" class="list-inside gap-1 grid text-3xl my-2">
          <Li><Badge rounded color="red" class="text-3xl">at scale</Badge></Li>
          <Li><Badge rounded color="purple" class="text-3xl">remotely</Badge></Li>
          <Li><Badge rounded color="yellow" class="text-3xl">around the clock</Badge></Li>
          <Li><Badge rounded color="blue" class="text-3xl">in real-time</Badge></Li>
          <Li><Badge rounded color="green" class="text-3xl">without human bias</Badge></Li>
        </List>
        </Secondary>
      and its assessments are <Span
        underline
        class="decoration-4 decoration-green-300 font-semibold text-gray-800">verified by
      SFIA accredited consultants. </Span>
      <P>
        ^ just in case
      </P>
    </Heading>
  </Layout>

  <Heading customSize="text-4xl my-8">
    <Secondary class="text-4xl my-10 font-medium text-gray-800">
      Ditch <Span class="line-through text-red-500">long interviews</Span>, <Span class="line-through text-red-500">tedious spreadsheets</Span>, and <Span class="line-through text-red-500">rigid questionnaires</Span> in favour of <Span class="text-green-500">personalised</Span> and <Span class="text-green-500">to-the-point</Span> questions, for a snappier assessment experience.
    </Secondary>
  </Heading>

  <DeviceMockup device="laptop" class="mt-8">
    <img src="/sfia-summary-chart.jpg" alt="SFIA summmary chart" />
  </DeviceMockup>

  <Heading customSize="text-4xl mt-8" id="request-access">
    <img src="/logo-64.jpg" alt="SFIA GPT logo" class="w-8 h-8 inline relative bottom-1" />
    SFIA GPT is currently in a <Span class="font-bold">closed beta</Span>. <br />
    To try it out, please request access by signing in via LinkedIn. <br />
    <a href="/login">
    <GradientButton shadow color="blue" class="mt-8 mx-auto w-full">
      Add me to the waitlist...
    </GradientButton>
    </a>
    <a href="/chat">
    <GradientButton shadow color="green" class="mb-8 mx-auto w-full">
      Already have access? Chat now.
    </GradientButton>
    </a>
    <Secondary class="text-3xl">
      Roadmap:
    </Secondary>
  </Heading>
  <Timeline class="mb-8 mt-4">
    <TimelineItem title="SFIA GPT v1" date="in two weeks">
      <p class="text-base font-normal text-gray-500 dark:text-gray-400">Stable API release.</p>
    </TimelineItem>
    <TimelineItem title="Get accredited by SFIA" date="in four-six weeks">
      <p class="text-base font-normal text-gray-500 dark:text-gray-400">Make SFIA GPT take the accreditation exam and itself become a SFIA consultant.
    </TimelineItem>
    <TimelineItem title="Add other frameworks" date="on demand">
      <p class="text-base font-normal text-gray-500 dark:text-gray-400">Add out-of-the-box support for other frameworks such as TOGAF, ITIL, COBIT, etc.</p>
      <p class="text-base font-normal text-gray-500 dark:text-gray-400">Adding custom skills is already supported.</p>
    </TimelineItem>
  </Timeline>
</main>
